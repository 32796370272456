import "core-js/modules/es.string.trim.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "ai-bot"
  }, [_c("a-card", {
    attrs: {
      bordered: false
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.createForm,
      layout: "inline"
    }
  }, [_c("FormItems", {
    attrs: {
      searchKeys: _vm.searchKeys,
      getDataWithKey: _vm.getDataWithKey
    },
    scopedSlots: _vm._u([{
      key: "button",
      fn: function fn() {
        return [_c("a-button", {
          attrs: {
            size: "large",
            type: "primary"
          },
          on: {
            click: _vm.handleSearch
          }
        }, [_vm._v("查询")]), _c("a-button", {
          staticStyle: {
            "margin-left": "20px",
            border: "0",
            background: "#f5f5f5"
          },
          attrs: {
            size: "large"
          },
          on: {
            click: _vm.handleReset
          }
        }, [_vm._v("重置")])];
      },
      proxy: true
    }])
  })], 1)], 1), _c("a-card", {
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      borderd: false
    }
  }, [_c("div", {
    staticClass: "tab-class"
  }, [_c("a-radio-group", {
    on: {
      change: _vm.TabChange
    },
    model: {
      value: _vm.tabActive,
      callback: function callback($$v) {
        _vm.tabActive = $$v;
      },
      expression: "tabActive"
    }
  }, [_c("a-radio-button", {
    attrs: {
      value: 1
    }
  }, [_vm._v(" 中文问答 ")]), _c("a-radio-button", {
    attrs: {
      value: 2
    }
  }, [_vm._v(" 英文问答 ")])], 1), _c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["im:aibot:create"],
      expression: "['im:aibot:create']"
    }],
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.addMoreAibot
    }
  }, [_vm._v("创建")])], 1), _c("a-table", {
    attrs: {
      pagination: false,
      dataSource: _vm.dataSource,
      columns: _vm.columns,
      rowKey: "id",
      loading: _vm.loading,
      scroll: {
        x: "100%"
      }
    },
    scopedSlots: _vm._u([{
      key: "questions",
      fn: function fn(questions, row) {
        return [_c("span", [_vm._v(_vm._s(questions[0]))])];
      }
    }, {
      key: "status",
      fn: function fn(status, row) {
        return [_c("span", [_vm._v(_vm._s(status == 0 ? "未生效" : "已生效"))])];
      }
    }, {
      key: "actions",
      fn: function fn(actions, row) {
        return [_c("div", {
          staticClass: "flex-spb"
        }, [_c("a-button", {
          on: {
            click: function click($event) {
              return _vm.handleButton("view", row);
            }
          }
        }, [_vm._v("查看")]), _c("a-button", {
          attrs: {
            type: "primary"
          },
          on: {
            click: function click($event) {
              return _vm.handleButton("edit", row);
            }
          }
        }, [_vm._v("管理")]), _c("a-button", {
          attrs: {
            type: "danger"
          },
          on: {
            click: function click($event) {
              return _vm.handleButton("del", row);
            }
          }
        }, [_vm._v("删除")])], 1)];
      }
    }])
  }), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.page,
      size: _vm.size,
      onPageSizeChange: _vm.onPageSizeChange,
      onShowSizeChange: _vm.onPageSizeChange
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:size": function updateSize($event) {
        _vm.size = $event;
      }
    }
  })], 1), _c("a-modal", {
    attrs: {
      title: "删除确认"
    },
    on: {
      ok: _vm.comfirmDel
    },
    model: {
      value: _vm.visibleDel,
      callback: function callback($$v) {
        _vm.visibleDel = $$v;
      },
      expression: "visibleDel"
    }
  }, [_c("p", [_vm._v("确认删除问答“" + _vm._s(_vm.delItem.questions && _vm.delItem.questions[0]) + "”吗？")])]), _c("a-modal", {
    attrs: {
      title: _vm.isView ? "查看" : "创建/管理",
      maskClosable: false
    },
    on: {
      cancel: _vm.cancelAdd
    },
    model: {
      value: _vm.visibleAdd,
      callback: function callback($$v) {
        _vm.visibleAdd = $$v;
      },
      expression: "visibleAdd"
    }
  }, [_c("a-radio-group", {
    staticClass: "mt-20",
    model: {
      value: _vm.languageType,
      callback: function callback($$v) {
        _vm.languageType = $$v;
      },
      expression: "languageType"
    }
  }, [_c("a-radio-button", {
    attrs: {
      value: 1
    }
  }, [_vm._v(" 中文问答 ")]), _c("a-radio-button", {
    attrs: {
      value: 2
    }
  }, [_vm._v(" 英文问答 ")])], 1), _c("a-form-model", _vm._b({
    ref: "addForm",
    staticClass: "mt-20",
    attrs: {
      model: _vm.addFormData
    }
  }, "a-form-model", _vm.layout, false), [_c("a-card", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.languageType == 1,
      expression: "languageType == 1"
    }],
    staticClass: "scroll-wrapper"
  }, [_c("a-form-model-item", {
    attrs: {
      label: _vm.labelList[0],
      prop: "cnAnswer",
      rules: {
        required: true,
        message: "请输入问题答复",
        trigger: "blur"
      }
    }
  }, [_c("a-textarea", {
    attrs: {
      "auto-size": {
        minRows: 3
      },
      placeholder: "回复",
      disabled: _vm.isView
    },
    model: {
      value: _vm.addFormData.cnAnswer,
      callback: function callback($$v) {
        _vm.$set(_vm.addFormData, "cnAnswer", $$v);
      },
      expression: "addFormData.cnAnswer"
    }
  })], 1), _vm._l(_vm.addFormData.cnQuestions, function (question, index) {
    return _c("a-form-model-item", {
      key: question.key,
      attrs: {
        label: index === 0 ? _vm.labelList[1] : " ",
        prop: "cnQuestions." + index + ".value",
        rules: {
          required: true,
          message: "请输入中文问题",
          trigger: "blur"
        }
      }
    }, [_c("a-input", {
      attrs: {
        placeholder: "问题" + (index + 1),
        disabled: _vm.isView
      },
      model: {
        value: question.value,
        callback: function callback($$v) {
          _vm.$set(question, "value", typeof $$v === "string" ? $$v.trim() : $$v);
        },
        expression: "question.value"
      }
    }), _c("a-icon", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.addFormData.cnQuestions.length - 1 == index && !_vm.isView,
        expression: "addFormData.cnQuestions.length - 1 == index && !isView"
      }],
      staticClass: "dynamic-button",
      attrs: {
        type: "plus-circle"
      },
      on: {
        click: function click($event) {
          return _vm.addMoreQuestion("cn");
        }
      }
    }), _c("a-icon", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.addFormData.cnQuestions.length > 1 && !_vm.isView,
        expression: "addFormData.cnQuestions.length > 1 && !isView"
      }],
      staticClass: "dynamic-button del",
      attrs: {
        type: "minus-circle-o"
      },
      on: {
        click: function click($event) {
          return _vm.removeQuestion("cn", index);
        }
      }
    })], 1);
  })], 2), _c("a-card", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.languageType == 2,
      expression: "languageType == 2"
    }],
    staticClass: "scroll-wrapper"
  }, [_c("a-form-model-item", {
    attrs: {
      label: _vm.labelList[0],
      prop: "enAnswer",
      rules: {
        required: true,
        message: "请输入问题答复",
        trigger: "blur"
      }
    }
  }, [_c("a-textarea", {
    attrs: {
      "auto-size": {
        minRows: 3
      },
      placeholder: "回复",
      disabled: _vm.isView
    },
    model: {
      value: _vm.addFormData.enAnswer,
      callback: function callback($$v) {
        _vm.$set(_vm.addFormData, "enAnswer", $$v);
      },
      expression: "addFormData.enAnswer"
    }
  })], 1), _vm._l(_vm.addFormData.enQuestions, function (question, index) {
    return _c("a-form-model-item", {
      key: question.key,
      attrs: {
        label: index === 0 ? _vm.labelList[1] : " ",
        prop: "enQuestions." + index + ".value",
        rules: {
          required: true,
          message: "请输入英文问题",
          trigger: "blur"
        }
      }
    }, [_c("a-input", {
      attrs: {
        placeholder: "问题" + (index + 1),
        disabled: _vm.isView
      },
      model: {
        value: question.value,
        callback: function callback($$v) {
          _vm.$set(question, "value", typeof $$v === "string" ? $$v.trim() : $$v);
        },
        expression: "question.value"
      }
    }), _c("a-icon", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.addFormData.enQuestions.length - 1 == index && !_vm.isView,
        expression: "addFormData.enQuestions.length - 1 == index && !isView"
      }],
      staticClass: "dynamic-button",
      attrs: {
        type: "plus-circle"
      },
      on: {
        click: function click($event) {
          return _vm.addMoreQuestion("en");
        }
      }
    }), _c("a-icon", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.addFormData.enQuestions.length > 1 && !_vm.isView,
        expression: "addFormData.enQuestions.length > 1 && !isView"
      }],
      staticClass: "dynamic-button del",
      attrs: {
        type: "minus-circle-o"
      },
      on: {
        click: function click($event) {
          return _vm.removeQuestion("en", index);
        }
      }
    })], 1);
  })], 2), _c("a-form-model-item", {
    staticClass: "mt-20",
    attrs: {
      label: "状态设置",
      prop: "status",
      rules: {
        required: true
      }
    }
  }, [_c("a-radio-group", {
    attrs: {
      disabled: _vm.isView
    },
    model: {
      value: _vm.addFormData.status,
      callback: function callback($$v) {
        _vm.$set(_vm.addFormData, "status", $$v);
      },
      expression: "addFormData.status"
    }
  }, [_c("a-radio", {
    attrs: {
      value: 1
    }
  }, [_vm._v(" 已生效 ")]), _c("a-radio", {
    attrs: {
      value: 0
    }
  }, [_vm._v(" 未生效 ")])], 1)], 1)], 1), _c("div", {
    staticClass: "flex-end",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("a-button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isView,
      expression: "!isView"
    }],
    on: {
      click: _vm.cancelAdd
    }
  }, [_vm._v("Cancel")]), _c("a-button", {
    attrs: {
      type: "primary",
      loading: _vm.submitloading
    },
    on: {
      click: _vm.comfirmAdd
    }
  }, [_vm._v("OK")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };